import React, {useRef, useState} from "react";
import { AlertCircle, ShoppingCart, Zap, Twitter, Check } from 'lucide-react';
import logo from "./assets/appicon.png";

function App() {
  const [basicEmail, setBasicEmail] = useState("");
  const [premiumEmail, setPremiumEmail] = useState("");
  const [basicSubmitted, setBasicSubmitted] = useState(false);
  const [premiumSubmitted, setPremiumSubmitted] = useState(false);
  const [basicError, setBasicError] = useState("");
  const [premiumError, setPremiumError] = useState("");
  const pricingSectionRef = useRef(null);

  const handleSignup = async (email, plan) => {
    const setError = plan === 0 ? setBasicError : setPremiumError;
    const setSubmitted = plan === 0 ? setBasicSubmitted : setPremiumSubmitted;

    setError(""); // Clear any previous errors

      if (!validateEmail(email)) {
          setError("Please enter a valid email address.");
          return;
      }
    console.log(`Signing up ${email} for ${plan} plan`);
    try {
      // send a POST request to the backend "https://jupara.xyz/api/waitlist/new"
        const response = await fetch("https://jupara.xyz/api/waitlist/new", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "email": email,
                "type": plan,
            }),
        });

        if (response.status === 409) {
            setError("This email is already on the waitlist.");
            return;
        }

        if (response.ok) {
          console.log("Successfully signed up!");
          if (plan === 0) {
            setBasicSubmitted(true);
          } else {
            setSubmitted(true);
          }
        }
    } catch (e) {
        console.error(e);
    }
  };

  const scrollToPricing = () => {
    pricingSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

  const renderSignupForm = (plan, email, setEmail, submitted, error, setError) => (
      <div className="mt-8">
          <input
              type="email"
              placeholder="Enter your email"
              className={`w-full px-4 py-2 rounded-full mb-2 ${submitted ? 'bg-gray-200 text-gray-500' : 'text-black'}
                ${error && !submitted ? 'border-2 border-red-500' : ''}`}
              value={email}
              onChange={(e) => {
                  setEmail(e.target.value);
                  setError(""); // Clear error when user starts typing
              }}
              disabled={submitted}
          />
          {error && <p className="text-red-500 mb-2">{error}</p>}
          <button
              onClick={() => handleSignup(email, plan)}
              className={`w-full px-6 py-3 rounded-full text-lg font-semibold transition duration-300 flex items-center justify-center
          ${submitted
                  ? 'bg-green-500 hover:bg-green-400'
                  : validateEmail(email)
                      ? plan === 'basic'
                          ? 'bg-purple-600 hover:bg-purple-500'
                          : 'bg-purple-500 hover:bg-purple-400'
                      : 'bg-gray-400 cursor-not-allowed'
              } text-white`}
              disabled={submitted || !validateEmail(email)}
          >
              {submitted ? <Check className="mr-2" size={24}/> : null}
              {submitted ? 'Joined' : 'Join Waitlist'}
          </button>
      </div>
  );

    // TODO: add to dev deps @babel/plugin-proposal-private-property-in-object

    return (
        <div className="min-h-screen bg-gradient-to-b from-purple-950 to-black text-purple-100">
            <header className="bg-purple-990 shadow-md">
                <div className="container mx-auto px-4 py-6 flex justify-between items-center">
                    <div className="flex items-center">
                        <img src={logo} alt="Jupara Logo" className="h-12 w-auto mr-3"/>
                        <h1 className="text-3xl font-bold text-purple-100">Jupara Freebies</h1>
                    </div>
                    <nav>
                        <ul className="flex space-x-4">
                            <li><a href="#features" className="text-purple-200 hover:text-white">Features</a></li>
                <li>
                  <button onClick={scrollToPricing} className="text-purple-200 hover:text-white">Pricing</button>
                </li>
                <li><a href="#contact" className="text-purple-200 hover:text-white">Contact</a></li>
              </ul>
            </nav>
          </div>
        </header>

        <main className="container mx-auto px-4 py-12">
          <section className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4 text-purple-100">Get Unbeatable Deals Automated</h2>
            <p className="text-xl text-purple-200 mb-8">Jupara finds and orders heavily discounted products for you,
              saving you time and money.</p>
            <button
                className="bg-purple-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-purple-500 transition duration-300"
                onClick={scrollToPricing}
            >
              Join Waitlist
            </button>
          </section>

          <section id="features" className="mb-16">
            <h3 className="text-3xl font-bold mb-8 text-center text-purple-100">How It Works</h3>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-purple-800 p-6 rounded-lg shadow-md">
                <ShoppingCart className="w-12 h-12 text-blue-300 mb-4"/>
                <h4 className="text-xl font-semibold mb-2 text-purple-100">Automated Orders</h4>
                <p className="text-purple-200">Our AI handles the entire order process from start to finish so you don't
                  have to lift a finger.</p>
              </div>
              <div className="bg-purple-800 p-6 rounded-lg shadow-md">
                <Zap className="w-12 h-12 text-blue-300 mb-4"/>
                <h4 className="text-xl font-semibold mb-2 text-purple-100">Instant Discounts</h4>
                <p className="text-purple-200">Get access to exclusive discounts and flash sales before they're
                  gone.</p>
              </div>
              <div className="bg-purple-800 p-6 rounded-lg shadow-md">
                <AlertCircle className="w-12 h-12 text-blue-300 mb-4"/>
                <h4 className="text-xl font-semibold mb-2 text-purple-100">Smart Alerts</h4>
                <p className="text-purple-200">Receive notifications throughout the order process and live updates from
                  our AI.</p>
              </div>
            </div>
          </section>

          <section id="pricing" className="mb-16" ref={pricingSectionRef}>
            <h3 className="text-3xl font-bold mb-8 text-center text-purple-200">Choose Your Beta Plan</h3>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="bg-purple-800 p-8 rounded-lg shadow-md border border-purple-600">
                <h4 className="text-2xl font-semibold mb-4 text-purple-200">Basic Membership</h4>
                <p className="text-4xl font-bold mb-4 text-purple-300">$75<span
                    className="text-xl font-normal text-purple-400"> Initial + </span></p>
                <p className="text-4xl font-bold mb-4 text-purple-300">$55<span
                    className="text-xl font-normal text-purple-400">/month</span></p>
                <ul className="mb-8 text-purple-300">
                  <li className="mb-2">✓ Automated order placement</li>
                  <li className="mb-2">✓ Access to daily deals</li>
                  <li className="mb-2">✓ 30 Account Limit</li>
                  <li className="mb-2">✓ Product restock module</li>
                </ul>
                {renderSignupForm(0, basicEmail, setBasicEmail, basicSubmitted, basicError, setBasicError)}
              </div>
              <div className="bg-purple-700 p-8 rounded-lg shadow-md border border-purple-500">
                <h4 className="text-2xl font-semibold mb-4 text-purple-100">Premium Membership</h4>
                <p className="text-4xl font-bold mb-4 text-purple-200">$100<span
                    className="text-xl font-normal text-purple-300"> Initial + </span></p>
                <p className="text-4xl font-bold mb-4 text-purple-200">$75<span
                    className="text-xl font-normal text-purple-300">/month</span></p>
                <ul className="mb-8 text-purple-200">
                  <li className="mb-2">✓ All Basic features</li>
                  <li className="mb-2">✓ Priority access to limited-time offers</li>
                  <li className="mb-2">✓ 50 Account Limit</li>
                  <li className="mb-2">✓ Advanced AI-powered modules</li>
                </ul>
                {renderSignupForm(1, premiumEmail, setPremiumEmail, premiumSubmitted, premiumError, setPremiumError)}
              </div>
            </div>
          </section>

          <section id="contact" className="text-center">
            <h3 className="text-3xl font-bold mb-4 text-purple-200">Want to Learn More?</h3>
            <p className="text-xl text-purple-300 mb-8">Follow us on Twitter for the latest updates and deal alerts!</p>
            <a
                href="https://twitter.com/JuparaFreebies"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center bg-purple-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-purple-500 transition duration-300"
            >
              <Twitter className="mr-2" size={24}/>
              Follow us on Twitter
            </a>
          </section>
        </main>

        <footer className="bg-black text-purple-300 py-8">
          <div className="container mx-auto px-4 text-center">
            <p>&copy; 2024 Jupara. All rights reserved.</p>
          </div>
        </footer>
      </div>
  );
}

export default App;
